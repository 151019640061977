.popup {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    transition: .4s all ease-in;
}