@import url('https://fonts.cdnfonts.com/css/pilot-command');
@import url('https://fonts.cdnfonts.com/css/truly-madly-dpad');
@import url('https://fonts.cdnfonts.com/css/bungee');

/* Tailwind */

@tailwind base;
@tailwind utilities;
@tailwind components;

/* Globals */

h1,
h2,
h3,
h4,
h5,
h6,
ul li a,
button {
    font-family: 'Bungee', sans-serif;
}